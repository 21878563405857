.leaflet-container {
  width: 100%;
  height: 100vh;
}

.icon-red {
  filter: hue-rotate(150deg);
}

.icon-yellow {
  filter: hue-rotate(250deg);
}